import { Component } from 'react';

import AppInfo from '../app-info/app-info';
import SearchPanel from '../search-panel/search-panel';
import AppFilter from '../app-filter/app-filter';
import EmployeesList from '../employees-list/employees-list';
import EmployeesAddForm from '../employees-add-form/employees-add-form';

import './app.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {name: 'John C.', salary: 800, increase: false, rise: true, id: 1},
                {name: 'Alex M.', salary: 3000, increase: true, rise: false, id: 2},
                {name: 'Carl W.', salary: 5000, increase: false, rise: false, id: 3}
            ],
            term: '',  // пустая строка для значения, введенного в строку поиска
            filter: 'all' // all - чтобы сразу показывались все сотрудники
        }
        this.maxId = 4; // для создания нового сотрудника
    }

    deleteItem = (id) => {
        this.setState(({data}) => {
            return {
                data: data.filter(item => item.id !== id) // останутся только те элементы массива, id которых не совпадает с тем, который пришел в эту функцию по клику
            }
        })
    }

    addItem = (name, salary) => {
        const newItem = {
            name, 
            salary,
            increase: false,
            rise: false,
            id: this.maxId++
        }
        this.setState(({data}) => {
            const newArr = [...data, newItem];
            return {
                data: newArr
            }
        });
    }

    onToggleProp = (id, prop) => {  // переключение и increase, и rise, они будут приходить в prop
        this.setState(({data}) => ({  // сразу возврат нового объекта
            data: data.map(item => { // из-за имммутабельности нужно использовать map, т.к. он создаст новый массив, а не переделает старый 
                if (item.id === id) {  // сравнение с id, который пришел из вызванного метода
                    return {...item, [prop]: !item[prop]}
                }
                return item; // если не совпадает, то просто возвращает тот же объект
            })
        }))
    }

    searchEmp = (items, term) => {
        if (term.length === 0) {
            // на случай, если написали что-то в поиске, а потом удалили
            return items;
        }

        return items.filter(item => {
            return item.name.indexOf(term) > -1
            // indexOf возвращает -1, если ничего не находит => надо в условии > -1
        })
    }

    onUpdateSearch = (term) => {
        this.setState({term});  // это "term: term"
    }

    filterPost = (items, filter) => {
        switch (filter) {
            case 'rise':
                return items.filter(item => item.rise); // новый массив только с теми, у кого rise = true
            case 'moreThen1000':
                return items.filter(item => item.salary > 1000);
            default:
                return items
        }
    }

    onFilterSelect = (filter) => {
        this.setState({filter}); // в состояние перезаписывается то, что пришло в этот метод
    }

    render() {
        const {data, term, filter} = this.state;
        const employees = this.state.data.length; // общее количество сотрудников (длина массива)
        const increased = this.state.data.filter(item => item.increase).length; // длина нового массива из тех, у кого increase = true
        const visibleData = this.filterPost(this.searchEmp(data, term), filter);
        // this.searchEmp(data, term) возвращает отфильтрованный по поиску массив, this.filterPost(..., ...) - по фильтру

        return (
            <div className="app">
                <AppInfo employees={employees} increased={increased}/>
    
                <div className="search-panel">
                    <SearchPanel onUpdateSearch={this.onUpdateSearch}/>
                    <AppFilter filter={filter}  // в prop.filter AppFilter идет state из App
                    onFilterSelect={this.onFilterSelect}/>
                    
                </div>
                
                <EmployeesList 
                    data={visibleData}
                    onDelete={this.deleteItem}
                    onToggleProp={this.onToggleProp}/>
                <EmployeesAddForm onAdd={this.addItem}/>
            </div>
        );
    }
}

export default App;