import './employees-list-item.css';

const EmployeesListItem = (props) => {

    const {name, salary, onDelete, onToggleProp, increase, rise} = props;

    let classNames = "list-group-item d-flex justify-content-between";
    if (increase) {   // т.е. если increase = true
        classNames += ' increase'; // пробел, чтобы названия классов не слиплись
    }
    if (rise) {
        classNames += ' like';
    }

    return (
        <li className={classNames}>
            <span className="list-group-item-label" onClick={onToggleProp} data-toggle="rise" // используется в EmployeesList в методе onToggleProp
            >{name}</span>
            <input type="text" className="list-group-item-input" defaultValue={salary + '$'}/>
            <div className='d-flex justify-content-center align-items-center'>
                <button type="button"  // кнопка для назначения премии ("печенька"), добавляет класс increase
                    className="btn-cookie btn-sm "
                    onClick={onToggleProp}
                    data-toggle="increase"> {/* используется в EmployeesList в методе onToggleProp */}
                    <i className="fas fa-cookie"></i>
                </button>

                <button type="button"
                        className="btn-trash btn-sm "
                        onClick={onDelete}>
                    <i className="fas fa-trash"></i>
                </button>
                <i className="fas fa-star"></i> {/* отметить, если на повышение (появится "звезда")  */}
            </div>
        </li>
    )
}

export default EmployeesListItem;