import EmployeesListItem from "../employees-list-item/employees-list-item";

import './employees-list.css';

const EmployeesList = ({data, onDelete, onToggleProp}) => {

    const elements = data.map(item => { // data с данными по сотрудникам передается через пропсы из App
        const {id, ...itemProps} = item;  // id в роли key понадобится для алгоритма согласования (реконцеляции)
        // ...itemProps - разворачивание каждого объекта (отдельного сотрудника) со всеми его свойствами 
        return (
            <EmployeesListItem 
                key={id} 
                {...itemProps}
                onDelete={() => onDelete(id)}  // получение функции по удалению onDelete из App и передача в пропсы EmployeesListItem 
                onToggleProp={(e) => onToggleProp(id, e.currentTarget.getAttribute('data-toggle'))}/>
                //  e.currentTarget.getAttribute('data-toggle') определяет, где произошел клик - на изменении rise или increase
        )
    })

    return (
        <ul className="app-list list-group">
            {elements}
        </ul>
    )
}

export default EmployeesList;