import { Component } from 'react';
import './search-panel.css';

class SearchPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            term: ''
            // это состояние нужно поднимать в App
        }
    }

    onUpdateSearch = (e) => {
        // это метод отдельно для этого компонента, чтобы создать управляемый компонент, название метода совпадает с методом в App
        const term = e.target.value;
        this.setState({term}); // установка состояния локально в этом компоненте
        this.props.onUpdateSearch(term)   // из App
    }

    render() {
        return (
            <input type="text"
                    className="form-control search-input" // классы из Bootstrap
                    placeholder="Найти сотрудника"
                    value={this.state.term}
                    onChange={this.onUpdateSearch}/>
        )
    }
}

export default SearchPanel;